import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";

export default () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(
    "<div>Enter your email below then click sign up!</div>"
  );

  const handleSubmit = async (event) => {
    const response = await addToMailchimp(email /*listFields*/);
    if (response.result === "success") {
      setMessage(response.msg);
    } else if (response.result === "error") {
      setMessage(`<div>Alert: ${response.msg}</div>`);
    } else {
      setMessage(
        "<div>Sorry we were unable to sign you up for our newsletter! Please reach out to us via our contact page.</div>"
      );
    }
  };

  const title = "Newsletter | Schoolhouse by the Sea";
  const description =
    "Sign up for our newsletter! Everyone enrolled to receive our newsletter is eligible for our monthly book give away.";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <div
        className="full-width-image-container"
        style={{
          backgroundImage: `url('/img/ocean-from-above.jpg')`,
        }}
      >
        <h1
          className="has-text-weight-bold is-size-1 has-text-centered"
          style={{
            backgroundColor: "#1FA8C2",
            color: "white",
            padding: "1rem 1.5rem",
          }}
        >
          Newsletter Signup
        </h1>
      </div>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <h2 className="title">Stay Connected With Us</h2>
              </div>
              <div className="content">
                Everyone enrolled to receive our newsletter is eligible for our
                monthly book give away. At the end of every month we will
                randomly select three families to receive FREE new books.
                Subscribe below and hopefully you'll hear from us soon!
              </div>
              <div className="content">
                <div style={{ paddingBottom: ".5rem" }}>
                  <strong>
                    <div dangerouslySetInnerHTML={{ __html: message }} />{" "}
                  </strong>
                </div>
                <div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <button
                    className="button"
                    onClick={async () => await handleSubmit(email)}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
